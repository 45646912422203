import { Breadcrumb, Button, Card, Col, Icon, Input, message, Radio, Row, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { Space } from '../../common-components/Space'
import { Link } from 'react-router-dom'
import { FormComponentProps } from 'antd/lib/form'
import { Form } from 'antd/es'
import { decryptDataVspace } from '../../common-misc'
import { JobMonitorList, myFilter } from './model'
import { getAlJobsMonitorWithPagination } from './service'


type Props = FormComponentProps
const serviceAPPROVAL = process.env.REACT_APP_BASE_URL_ROLE_APPROVAL!!
const JobMonitor: React.FC<Props> = (props: Props) => {
    const dataVspace = decryptDataVspace()
    let email: string = ''
    let role: string = ''
    if (typeof dataVspace === 'object') {
        email = dataVspace.email
        role = dataVspace.roleId.toString()
    }
    const { getFieldDecorator } = props.form
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const status = ['All Status', 'Open Status (Open, In Progress)', 'Open', 'In Progress', 'Closed', 'Cancelled']
    const [filterStatus, setFilterStatus] = useState<string | undefined>(Number(role) !== 799 ? 'All Status' : 'Awaiting Approval')
    const [jobDataList, setJobDataList] = useState<JobMonitorList[]>()
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [total, setTotal] = useState<number>(1)
    const [orderBy, setOrderBy] = useState<string>('desc')
    const [sortBy, setSortBy] = useState<string>('createdDatetime')
    const [searchFieldValue, setSearchFieldValue] = useState<any>()

    useEffect(() => {
        setIsLoading(true)
        getAlJobsMonitorWithPagination(page, pageSize, filterStatus, sortBy, orderBy, searchFieldValue).then((res) => {
            if (res) {
                setJobDataList(res.content)
                setTotal(res.totalElements)
            }
        }).catch((err) => {
            message.error(`Failed fetching data. ${err}`)
        }).finally(() => { setIsLoading(false)})
    }, [sortBy, orderBy, page, filterStatus, searchFieldValue, pageSize])

    const getColumnSearchProps = (dataIndex: any) => ({
        // eslint-disable-next-line react/display-name
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any): any => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${checkTextPlaceholder(dataIndex)}`}
                    value={selectedKeys[0]}
                    onChange={(e: any) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8, color: 'red' }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        onFilter: (value: any, record: any) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        // eslint-disable-next-line react/display-name
        filterIcon: (filtered: any) => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
            }
        }
    })

    const handleFilterStatus = (e) => {
        setFilterStatus(e.target.value)
        setIsLoading(true)
        // props.getAllWorkOrdersWithPagination(page, pageSize, role, '', e.target.value, sortBy, orderBy).then((res) => {
        //     if (res) {
        //         setIsLoading(false)
        //     }
        // }).catch((err) => {
        //     setIsLoading(false)
        //     message.error(`Failed fetching data. ${err}`)
        // })
    }

    const columns = [
        {
            title: 'Order ID',
            dataIndex: 'workorderNumber',
            key: 'workorderNumber',
            sorter: true,
            ...getColumnSearchProps('workorderNumber'),
            render: (record, data: JobMonitorList) => {
                // const incNo = props.workOrderList.find(res => res.workOrder?.number === record)
                return (
                    <div style={{ margin: 'unset' }}>{<Link to={`/WoFormEdit/${data.workorderID}/Job`}>{record}</Link>}</div>
                )
            }
        },
        {
            title: 'Job Number',
            dataIndex: 'jobNumber',
            key: 'jobNumber',
            sorter: true,
            ...getColumnSearchProps('jobNumber')
        },
        {
            title: 'WO Topic',
            dataIndex: 'wovTopic',
            key: 'wovTopic',
            sorter: true,
            ...getColumnSearchProps('wovTopic')
        },
        {
            title: 'WO Assignment Group',
            dataIndex: 'wovSupportTeam',
            key: 'wovSupportTeam',
            sorter: true,
            ...getColumnSearchProps('wovSupportTeam')
        },
        {
            title: 'Job Topic',
            dataIndex: 'jobTopic',
            key: 'jobTopic',
            sorter: true,
            ...getColumnSearchProps('jobTopic'),
            // render: (record) => {
            //     return (
            //         <>
            //             {
            //                 <Tooltip placement="top" title={record}>
            //                     <p style={{ margin: 'unset' }}>{record.length > 10 ? (<>{record.substring(0, 10) + '...'}</>) : (<>{record}</>)}</p>
            //                 </Tooltip>
            //             }
            //         </>
            //     )
            // }
        },
        {
            title: 'Job Template',
            dataIndex: 'jobTemplate',
            key: 'jobTemplate',
            sorter: true,
            ...getColumnSearchProps('jobTemplate'),
        },
        {
            title: 'Job Status',
            dataIndex: 'jobStatus',
            key: 'jobStatus',
            sorter: true,
            ...getColumnSearchProps('jobStatus')
        },
        {
            title: 'Created date',
            dataIndex: 'createdDatetime',
            key: 'createdDatetime',
            sorter: true,
            ...getColumnSearchProps('createdDatetime')
        },
        {
            title: 'Updated date',
            dataIndex: 'lastModifiedDatetime',
            key: 'lastModifiedDatetime',
            sorter: true,
            ...getColumnSearchProps('lastModifiedDatetime')
        },
        {
            title: 'Current Time Spent',
            dataIndex: 'currentTimeSpent',
            key: 'currentTimeSpent',
            sorter: true,
            ...getColumnSearchProps('currentTimeSpent'),
        },
        {
            title: 'Job Assignment group',
            dataIndex: 'supportTeam',
            key: 'supportTeam',
            sorter: true,
            ...getColumnSearchProps('supportTeam'),
        },
        {
            title: 'Job Assigned to',
            dataIndex: 'assignee',
            key: 'assignee',
            sorter: true,
            ...getColumnSearchProps('assignee'),
        },
    ]

    const handleSearch = (selectedKeys: any, confirm: any) => {
        confirm()
        // setSearchText(selectedKeys[0])
    }

    const handleReset = (clearFilters: any) => {
        clearFilters()
        // setSearchText('')
    }

    const getProperty = (obj, key) => {
        return obj[key]
    }

    const checkTextPlaceholder = (value: string): string => {
        let tempValue: string = value
        if (tempValue === 'number') {
            tempValue = 'Order ID'
        } else if (tempValue === 'topic') {
            tempValue = 'Topic'
        } else if (tempValue === 'contactUserName') {
            tempValue = 'Contact User'
        } else if (tempValue === 'status') {
            tempValue = 'Status'
        } else if (tempValue === 'contactUserLocation') {
            tempValue = 'Location'
        } else if (tempValue === 'supportTeam') {
            tempValue = 'Assignment group'
        } else if (tempValue === 'assignee') {
            tempValue = 'Assigned to'
        } else if (tempValue === 'lastModifiedDate') {
            tempValue = 'Updated date'
        } else if (tempValue === 'closedJobs') {
            tempValue = 'Job'
        } else if (tempValue === 'type') {
            tempValue = 'Type'
        } else if (tempValue === 'createdDate') {
            tempValue = 'Created Date'
        } else if (tempValue === 'jobTopic') {
            tempValue = 'Job Topic'
        }
        return tempValue
    }

    const filterGroup = (dataSource: string[]) => {
        return (
            <Row style={{ marginTop: 20 }}>
                {getFieldDecorator('Filter', {
                    initialValue: filterStatus
                })(
                    <Radio.Group onChange={(value) => {
                        handleFilterStatus(value)
                    }}>
                        {dataSource.map((item) => {
                            return (
                                <Radio.Button
                                    key={item}
                                    value={item}
                                >{item}</Radio.Button>
                            )
                        })}
                    </Radio.Group>
                )}
            </Row>
        )
    }

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        if (sorter.field) {
            if (sorter.order === 'ascend') {
                setOrderBy('asc')
                if (sorter.field) {
                    setSortBy(sorter.field)
                } else {
                    setSortBy('createdDatetime')
                }
            } else if (sorter.order === 'descend') {
                setOrderBy('desc')
                if (sorter.field) {
                    setSortBy(sorter.field)
                } else {
                    setSortBy('createdDatetime')
                }
            } else {
                setSortBy('createdDatetime')
            }
        }
        if (filters) {
            const newObject = {}
            for (const key of Object.keys(filters)) {
                if (getProperty(filters, key)[0]) {
                    newObject[key] = getProperty(filters, key)[0]
                }
            }
            setSearchFieldValue(newObject)
        }

        // setTotalWO(pagination.total || 0)
        setPageSize(pagination.pageSize)
        setPage(pagination.current)
    }

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>Job Monitor</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={20} />
            <Card style={{ overflow: 'auto' }}>
                <div>
                    <h3 className='main-title'>Job Monitor ({total})</h3>
                </div>
                {/* {
                    Number(role) !== parseInt(serviceAPPROVAL)
                        ? filterGroup(status)
                        : filterGroup(statusForApprove)
                } */}
                {filterGroup(status)}
                <br />
                <div style={{ overflow: 'auto' }}>
                    <Table
                        rowKey={'jobNumber'}
                        columns={columns}
                        dataSource={jobDataList}
                        onChange={handleTableChange}
                        pagination={{
                            pageSize: pageSize,
                            current: page,
                            total: total,
                            showSizeChanger: true,
                            onChange: (event) => { handleOnClickPageNumber(event) }
                        }}
                        loading={isLoading}
                    />
                </div>
            </Card>
        </>
    )
}
const WrappedJobMonitor = Form.create<Props>({ name: 'JobMonitor' })(JobMonitor)
export default (WrappedJobMonitor)